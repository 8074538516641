import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

function openNewTabWithPost(url, data) {
  const form = document.createElement("form");
  form.method = "post";
  form.action = url;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }
  }

  const newTab = window.open("", "_self");
  newTab.document.body.appendChild(form);
  form.submit();
}

const DataContainer = ({
  amount,
  token,
  setSADADNumber,
  setSADADError,
  SADADToggle,
  setLoading,
}) => {
  const phoneRegExp = /^[0-9]{10}$/;
  const vatRegExp = /^[0-9]{15}$/;
  const idRegExp = /^[0-9]+$/;

  const validationSchema = Yup.object().shape({
    company: Yup.string().required("required"),
    mobile: Yup.string().matches(phoneRegExp, "invalid").required("required"),
    email: Yup.string().email("invalid").required("required"),
    vat: Yup.string().matches(vatRegExp, "invalid"),
    payment: Yup.string().required("required"),
    id: Yup.string().matches(idRegExp, "invalid"),
  });

  const validateHandler = (values) => {
    let errors = {};

    if (values.payment === "SADAD" && !values.id) errors.id = "required";

    // console.log("values", values);
    // console.log("errors", errors);

    return errors;
  };
  const submitHandler = (values, { resetForm }) => {
    // console.log("submitted values -----------", values);
    setLoading(true);

    const formData = {
      Token: token,
      Name: values.company,
      Phone: values.mobile,
      Email: values.email,
      VAT: values.vat,
      Pmethod: values.payment,
    };
    if (values.payment === "SADAD") formData.NID = values.id;

    setSADADNumber("");
    setSADADError("");
    axios
      .post("https://testadmin.cpvarabia.com/Add/checkout", formData)
      .then((res) => {
        if (res.data.error === false && values.payment === "SADAD") {
          setSADADNumber(res.data.payment_Data);
          SADADToggle();
        }
        if (res.data.error === true && values.payment === "SADAD") {
          setSADADError(res.data.message);
          SADADToggle();
        }
        if (
          res.data.error === false &&
          (values.payment === "STC" || values.payment === "CC")
        ) {
          openNewTabWithPost(
            "https://sbcheckout.payfort.com/FortAPI/paymentPage",
            JSON.parse(res.data.payment_Data)
          );
        }

        resetForm();
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      // style={{ boxShadow: "1px 1px 5px lightgray" }}
      className="p-3 px-lg-5"
      style={{ width: "100%" }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          company: "",
          email: "",
          mobile: "",
          vat: "",
          payment: "",
          id: "",
        }}
        validationSchema={validationSchema}
        validate={validateHandler}
        onSubmit={submitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <FormGroup>
              <Label>Company Name</Label>
              <Input
                type="text"
                name="company"
                value={props.values.company}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              {props.errors.company && props.touched.company ? (
                <p className="invalid-feedback d-flex justify-content-end px-2 pt-0">
                  {props.errors.company}
                </p>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                name="email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              {props.errors.email && props.touched.email ? (
                <p className="invalid-feedback d-flex justify-content-end px-2 pt-0">
                  {props.errors.email}
                </p>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>Mobile</Label>
              <Input
                type="text"
                name="mobile"
                value={props.values.mobile}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              {props.errors.mobile && props.touched.mobile ? (
                <p className="invalid-feedback d-flex justify-content-end px-2 pt-0">
                  {props.errors.mobile}
                </p>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>Vat Number</Label>
              <Input
                type="text"
                name="vat"
                value={props.values.vat}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              {props.errors.vat && props.touched.vat ? (
                <p className="invalid-feedback d-flex justify-content-end px-2 pt-0">
                  {props.errors.vat}
                </p>
              ) : null}
            </FormGroup>

            <Label>Payment</Label>
            {props.errors.payment && props.touched.payment ? (
              <p className="invalid-feedback d-flex justify-content-end px-2 pt-0">
                {props.errors.payment}
              </p>
            ) : null}
            <ul style={{ listStyle: "none" }}>
              <li>
                <Input
                  type="radio"
                  name="payment"
                  value="CC"
                  id="CC"
                  checked={props.values.payment === "CC"}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <Label htmlFor="CC" className="mx-2">
                  Debit / Credit Card
                </Label>
              </li>
              <li>
                <Input
                  type="radio"
                  name="payment"
                  value="STC"
                  id="STC"
                  checked={props.values.payment === "STC"}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <Label htmlFor="STC" className="mx-2">
                  STC Pay
                </Label>
              </li>
              {/* <li>
                <Input
                  type="radio"
                  name="payment"
                  value="SADAD"
                  id="SADAD"
                  checked={props.values.payment === "SADAD"}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <Label htmlFor="SADAD" className="mx-2">
                  SADAD
                </Label>
              </li> */}
            </ul>

            {props.values.payment === "SADAD" ? (
              <FormGroup>
                <Label>National ID</Label>
                <Input
                  type="text"
                  name="id"
                  value={props.values.id}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                {props.errors.id && props.touched.id ? (
                  <p className="invalid-feedback d-flex justify-content-end px-2 pt-0">
                    {props.errors.id}
                  </p>
                ) : null}
              </FormGroup>
            ) : null}

            <FormGroup className="d-flex justify-content-center mt-5">
              <Button
                type="submit"
                style={{ width: "100%" }}
                className="bg-primary"
              >
                Pay {amount} SAR
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DataContainer;
