import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import LogoContainer from "../components/LogoContainer";
import DataContainer from "../components/DataContainer";
import SADADPayment from "../components/SADADPayment";
import axios from "axios";
import Loader from "../components/Loader";
import { useSearchParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
} from "reactstrap";

const Home = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("Token");
  // const token = "kjkj4k4k4kb5kj54";
  console.log("token", token);

  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [SADADNumber, setSADADNumber] = useState("");
  const [SADADError, setSADADError] = useState("");
  const [SADADModal, setSADADModal] = useState(false);
  const SADADToggle = () => {
    setSADADModal(!SADADModal);
  };

  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    axios
      .post("https://testadmin.cpvarabia.com/checkout.php", {
        Token: token,
      })
      .then((res) => {
        console.log(res);
        if (res.data.error === true) {
          setErrorMessage(res.data.message);
        } else {
          setAmount(res.data.Amount);
          setDescription(res.data.description);
        }
        setPageLoading(false);
      })
      .catch((err) => console.log(err));
  }, [token]);

  if (!token) {
    // setTimeout(() => {
    //   window.location.replace("https://CPVarabia.com");
    // }, [5000]);
    return (
      <div
        style={{
          height: "90vh",
          width: "90vw",
          margin: "auto",
          position: "relative",
        }}
      >
        <Row
          style={{ height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Col sm={10} md={6} lg={6} className="my-5">
            <Card
              className="my-2 mx-auto text-center"
              style={{
                width: "100%",
                border: "none",
                backgroundColor: "lightgray",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.30)",
              }}
            >
              <CardBody className="py-5">
                <h3>No Token Provided</h3>
              </CardBody>
            </Card>
            <div>
              <h5 className="text-muted text-center">CPV Arabia LTD</h5>
            </div>
          </Col>
        </Row>
      </div>
    );
  } else if (pageLoading) {
    return (
      <div
        style={{
          height: "90vh",
          width: "90vw",
          margin: "auto",
          position: "relative",
        }}
      >
        <Loader type="spin" color="gray" />
      </div>
    );
  } else if (!amount) {
    // setTimeout(() => {
    //   window.location.replace("https://CPVarabia.com");
    // }, [5000]);
    return (
      <div
        style={{
          height: "90vh",
          width: "90vw",
          margin: "auto",
          position: "relative",
        }}
      >
        <Row
          style={{ height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Col sm={10} md={6} lg={6} className="my-5">
            <Card
              className="my-2 mx-auto text-center"
              style={{
                width: "100%",
                border: "none",
                backgroundColor: "lightgray",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.30)",
              }}
            >
              <CardBody className="py-5">
                <h3>
                  <i className="bi bi-receipt mx-3" />
                  {errorMessage}
                </h3>
              </CardBody>
            </Card>
            <div>
              <h5 className="text-muted text-center">CPV Arabia LTD</h5>
            </div>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <>
        <div
          style={{
            height: "90vh",
            width: "90vw",
            margin: "auto",
            position: "relative",
          }}
        >
          {loading && <Loader type="spin" color="gray" />}
          <Row
            style={{ height: "100%" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Col md={10} lg={8} className="my-5">
              <Row
                className="p-0"
                style={{
                  borderRadius: "10px",
                  boxShadow: "1px 1px 10px #888",
                  overflow: "hidden",
                }}
              >
                <Col md={6} className="p-0">
                  <LogoContainer amount={amount} description={description} />
                </Col>
                <Col
                  md={6}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <DataContainer
                    amount={amount}
                    token={token}
                    setSADADNumber={setSADADNumber}
                    setSADADError={setSADADError}
                    SADADToggle={SADADToggle}
                    setLoading={setLoading}
                  />
                </Col>
              </Row>

              <Row className="my-5">
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <a
                    href="https://cpvarabia.com/Terms_and_Conditions.html"
                    target="_balnk"
                    // className="mx-5"
                  >
                    Terms, Conditions and Cancellation Policy
                  </a>
                </Col>
                <Col
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <a
                    href="https://cpvarabia.com/arTerms_and_Conditions.html"
                    target="_balnk"
                    // className="mx-5"
                  >
                    تطبق الشروط والأحكام وسياسة الاسترداد
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          {SADADModal && (
            <SADADPayment
              SADADToggle={SADADToggle}
              SADADNumber={SADADNumber}
              SADADError={SADADError}
            />
          )}
        </div>

        {/* <div
          // style={{ backgroundColor: "red" }}
          className="d-flex justify-content-center align-items-center"
        >
          <a
            href="https://cpvarabia.com/Terms_and_Conditions.html"
            target="_balnk"
            style={{ margin: "10px" }}
          >
            Terms, Conditions and Cancellation Policy
          </a>

          <a
            href="https://cpvarabia.com/arTerms_and_Conditions.html"
            target="_balnk"
            style={{ margin: "10px" }}
          >
            تطبق الشروط والأحكام وسياسة الاسترداد
          </a>
        </div> */}
      </>
    );
  }
};

export default Home;
